import React from 'react'
import tw, { css, theme } from 'twin.macro'
import { global } from '../styles/global'
import { fluid, remToPx } from '../styles/typography'
import { HTMLType, ImageType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Image from './image'
import TextBlock from './text-block'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ImageHero = ({
  style,
  contentStyle,
  image,
  imageMobile,
  title,
  descriptions,
  callToAction,
}) => {
  const carouselSettings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    pauseOnHover: false,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div
      css={[
        css`
          ${tw`relative bg-secondary-500 h-hero-mobile lg:h-hero-default`}
          strong {
            ${tw`font-medium`}
          }
        `,
        style,
      ]}
    >
      <Image
        image={image}
        imageMobile={imageMobile}
        style={tw`absolute! top-0 bottom-0 left-0 w-full`}
      />
      <div css={[global`layout.container`, global`layout.grid`, tw`absolute inset-0`]}>
        <div css={[tw`flex flex-col items-start col-span-10`, contentStyle]}>
          <Heading
            headingType="h1"
            content={title}
            style={[
              tw`mt-20 lg:mt-40 text-primary-500`,
              fluid(remToPx(theme`fontSize.mobile-hero`), remToPx(theme`fontSize.hero`)),
            ]}
          />
          {
            descriptions && descriptions.length ? (
              <div css={tw`w-full`}>
                <Slider {...carouselSettings}>
                    {descriptions.map((text, i) => (
                        <TextBlock
                          key={i}
                          text={text.textNode}
                        />
                    ))}
                </Slider>
              </div>
            ) : (
              <TextBlock
                text={descriptions}
              />
            )
          }
          {callToAction && (
            <Button
              type="primary"
              size="sm"
              theme="navy-white"
              label={callToAction.label}
              link={callToAction.link}
              style={tw`mt-6 lg:mt-12`}
              hideCaret
            />
          )}
        </div>
      </div>
    </div>
  )
}

ImageHero.propTypes = {
  style: StyleType,
  contentStyle: StyleType,
  image: ImageType,
  imageMobile: ImageType,
  title: HTMLType,
  callToAction: LinkType,
}

export default ImageHero
